body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-sections{
  width: 100vw;
  padding: 4.5% 6% 1%;
}

.title-sections{
  font-size: 6.58vw;
  font-family: Roboto-Bold;
  line-height: 1.05;
  letter-spacing: 0px;
  color: #1D3772;
  opacity: 1;
}

.title2-sections{
  display: inline-block;
  width: 85%;
  font-size: 2.2vw;
  font-family: Roboto-Bold;
  letter-spacing: 0px;
  color: #0078C1;
  opacity: 1;
}

.title3-sections{
  width: 66%;
  display: inline-block;
  font-size: 1.91vw;
  font-family: Roboto-Medium;
  letter-spacing: 0px;
  color: #122E5F;
  margin-bottom: 4vw;
  opacity: 1;
}

.subtitle-sections{
  display: inline-block;
  width: 85%;
  font-size: 2.2vw;
  font-family: Roboto-Bold;
  letter-spacing: 0px;
  color: #0078C1;
  opacity: 1;
}

.subtitle-sections2{
  display: inline-block;
  width: 85%;
  font-size: 2.2vw;
  font-family: Roboto-Bold;
  letter-spacing: 0px;
  color: #25C8F6;
  opacity: 1;
}

.content-sections{
  display: inline-block;
  width: 78%;
  font-size: 1.32vw;
  font-family: Roboto-Regular;
  letter-spacing: 0px;
  color: #122E5F;
  opacity: 1;
}


@media screen and (max-width: 687px), (max-height: 325px) {
  .container-sections{
    padding: 8% 6% 1%;
  }
  
  .title-sections{
    width: 100%;
    font-size: 10.3vw;
  }

  .title2-sections{
    width: 100%;
    font-size: 5.15vw;
  }
  
  .title3-sections{
    width: 100%;
    font-size: 4.7vw;
  }
  
  .subtitle-sections{
    width: 100%;
    font-size: 5.15vw;
  }
  
  .subtitle-sections2{
    width: 100%;
    font-size: 5.15vw;
  }
  
  .content-sections{
    width: 100%;
    font-size: 4.7vw;
  }

  .div_separatorBootom_formRegister{
    width: 100%;
    height: 10vw;
    display: inline-block;
  }
}


@font-face {
  font-family: Roboto-Black;
  src: url(./Fonts/Roboto-Black.woff);
}

@font-face {
  font-family: Roboto-BlackItalic;
  src: url(./Fonts/Roboto-BlackItalic.woff);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(./Fonts/Roboto-Bold.woff);
}

@font-face {
  font-family: Roboto-BoldItalic;
  src: url(./Fonts/Roboto-BoldItalic.woff);
}

@font-face {
  font-family: Roboto-Italic;
  src: url(./Fonts/Roboto-Italic.woff);
}

@font-face {
  font-family: Roboto-Light;
  src: url(./Fonts/Roboto-Light.woff);
}

@font-face {
  font-family: Roboto-LightItalic;
  src: url(./Fonts/Roboto-LightItalic.woff);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(./Fonts/Roboto-Medium.woff);
}

@font-face {
  font-family: Roboto-MediumItalic;
  src: url(./Fonts/Roboto-MediumItalic.woff);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(./Fonts/Roboto-Regular.woff);
}

@font-face {
  font-family: Roboto-Thin;
  src: url(./Fonts/Roboto-Thin.woff);
}

@font-face {
  font-family: Roboto-ThinItalic;
  src: url(./Fonts/Roboto-ThinItalic.woff);
}

@font-face {
  font-family: GandhiSans-Bold;
  src: url(./Fonts/GandhiSans-Bold.otf);
}

@font-face {
  font-family: JustTellMeWhat-Italic;
  src: url(./Fonts/JustTellMeWhat-Italic.otf);
}

@font-face {
  font-family: JustTellMeWhat-Regular;
  src: url(./Fonts/JustTellMeWhat-Regular.otf);
}