@import url(https://fonts.googleapis.com/css?family=Quicksand:400,300,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-sections{
  width: 100vw;
  padding: 4.5% 6% 1%;
}

.title-sections{
  font-size: 6.58vw;
  font-family: Roboto-Bold;
  line-height: 1.05;
  letter-spacing: 0px;
  color: #1D3772;
  opacity: 1;
}

.title2-sections{
  display: inline-block;
  width: 85%;
  font-size: 2.2vw;
  font-family: Roboto-Bold;
  letter-spacing: 0px;
  color: #0078C1;
  opacity: 1;
}

.title3-sections{
  width: 66%;
  display: inline-block;
  font-size: 1.91vw;
  font-family: Roboto-Medium;
  letter-spacing: 0px;
  color: #122E5F;
  margin-bottom: 4vw;
  opacity: 1;
}

.subtitle-sections{
  display: inline-block;
  width: 85%;
  font-size: 2.2vw;
  font-family: Roboto-Bold;
  letter-spacing: 0px;
  color: #0078C1;
  opacity: 1;
}

.subtitle-sections2{
  display: inline-block;
  width: 85%;
  font-size: 2.2vw;
  font-family: Roboto-Bold;
  letter-spacing: 0px;
  color: #25C8F6;
  opacity: 1;
}

.content-sections{
  display: inline-block;
  width: 78%;
  font-size: 1.32vw;
  font-family: Roboto-Regular;
  letter-spacing: 0px;
  color: #122E5F;
  opacity: 1;
}


@media screen and (max-width: 687px), (max-height: 325px) {
  .container-sections{
    padding: 8% 6% 1%;
  }
  
  .title-sections{
    width: 100%;
    font-size: 10.3vw;
  }

  .title2-sections{
    width: 100%;
    font-size: 5.15vw;
  }
  
  .title3-sections{
    width: 100%;
    font-size: 4.7vw;
  }
  
  .subtitle-sections{
    width: 100%;
    font-size: 5.15vw;
  }
  
  .subtitle-sections2{
    width: 100%;
    font-size: 5.15vw;
  }
  
  .content-sections{
    width: 100%;
    font-size: 4.7vw;
  }

  .div_separatorBootom_formRegister{
    width: 100%;
    height: 10vw;
    display: inline-block;
  }
}


@font-face {
  font-family: Roboto-Black;
  src: url(/static/media/Roboto-Black.d13b7303.woff);
}

@font-face {
  font-family: Roboto-BlackItalic;
  src: url(/static/media/Roboto-BlackItalic.1d3e9d67.woff);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(/static/media/Roboto-Bold.5058c3f7.woff);
}

@font-face {
  font-family: Roboto-BoldItalic;
  src: url(/static/media/Roboto-BoldItalic.5a14c4ef.woff);
}

@font-face {
  font-family: Roboto-Italic;
  src: url(/static/media/Roboto-Italic.c33a6de8.woff);
}

@font-face {
  font-family: Roboto-Light;
  src: url(/static/media/Roboto-Light.08e6b787.woff);
}

@font-face {
  font-family: Roboto-LightItalic;
  src: url(/static/media/Roboto-LightItalic.a15a04ba.woff);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(/static/media/Roboto-Medium.977fec48.woff);
}

@font-face {
  font-family: Roboto-MediumItalic;
  src: url(/static/media/Roboto-MediumItalic.ee1fe5c3.woff);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(/static/media/Roboto-Regular.5aa400b9.woff);
}

@font-face {
  font-family: Roboto-Thin;
  src: url(/static/media/Roboto-Thin.ac74d83c.woff);
}

@font-face {
  font-family: Roboto-ThinItalic;
  src: url(/static/media/Roboto-ThinItalic.74918f4f.woff);
}

@font-face {
  font-family: GandhiSans-Bold;
  src: url(/static/media/GandhiSans-Bold.dd39fc91.otf);
}

@font-face {
  font-family: JustTellMeWhat-Italic;
  src: url(/static/media/JustTellMeWhat-Italic.e86e69fb.otf);
}

@font-face {
  font-family: JustTellMeWhat-Regular;
  src: url(/static/media/JustTellMeWhat-Regular.b9937132.otf);
}
.App{
    position: relative;
    min-height:100vh;
    min-width: 100vw;
    padding-top: 4.5vw; /* espacio header */
    padding-bottom: 8vw; /* espacio footer */
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .App{
        padding-top: 12vw;
        padding-bottom: 28vw;
    }
}
/* styles.css */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  width: 100%;
  margin: 0 auto;
  background-color: #1c1c1c;
  font-family: "Quicksand", sans-serif;
  overflow: hidden;
}

#background {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  overflow: hidden;
}

.main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
}

#content {
  width: 100%;
  max-width: 800px;
  margin: 15px auto;
  min-height: 24px;
  height: 100%;
  position: relative;
  text-align: center;
  top: 18%;
}

.title {
  color: white;
  font-family: "Quicksand", sans-serif;
  font-size: 4rem;
  text-transform: uppercase;
  padding-bottom: 0px;
  margin-bottom: 0px;
  cursor: pointer;

}

img {
  width: 150px;
  height: 150px;
  cursor:pointer
}
img:hover {
  -webkit-animation: MISSION-HOVER 1.5s infinite;
  animation: MISSION-HOVER 1.5s infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}

@-webkit-keyframes MISSION-HOVER {
  0%, 100% {
      font-size: 4rem;
      color: white;
  }
  50% {
      color: #bffcff;
      letter-spacing: 5px;
      text-shadow: 0px 0px 30px rgba(191, 252, 255, 1);
  }
}

p {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 34pt;
  margin: 46px auto;
  padding: 0;
  text-transform: uppercase;
}

p:last-child {
  font-size: 0.75rem;
  font-weight: 700;
  margin: 3em auto;
  padding: 0;
  letter-spacing: 0.1rem;
}

p:last-child a {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-in;
}

p:last-child a:hover {
  color: #fff;
}

section {
  color: #fff;
  margin: 0 auto;
  line-height: 24px;
  font-size: 3rem;
  font-weight: 700;
}

ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;
}

li {
  display: inline-block;
  margin-right: 4rem;
  width: 6rem;
  white-space: nowrap;
}

@media (max-width: 768px) {
  li {
    margin-right: 1rem; 
    width: auto; 
    font-size: 80%; 
  }
}

.timenumbers {
  display: block;
  font-size: 34pt;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 0 auto;
  text-align: center;
}

p.timedescription {
  font-size: 0.7rem;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  line-height: 1.5rem;
  margin: 10px auto;
  text-align: center;
  position: relative;
  top: 0px;
  color:#20b8e8

}

.fix-it {
  position: fixed !important;
  top: 0;
}

.fade-in {
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
}

.fade-in.active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
