.App{
    position: relative;
    min-height:100vh;
    min-width: 100vw;
    padding-top: 4.5vw; /* espacio header */
    padding-bottom: 8vw; /* espacio footer */
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .App{
        padding-top: 12vw;
        padding-bottom: 28vw;
    }
}